import React from 'react';
import Layout from '../../components/Layout';
import CostSupport, {
  treatmentCostEM
} from '../../components/CostSupport/CostSupport';
import QuickOverview, {
  endometriosis
} from '../../components/QuickOverview/QuickOverview';
import InnerReadyToStart from '../../components/ReadyToStart/InnerReadyToStart';
import Hero, { HeroHomeEMContent } from '../../components/Hero/Hero';
import QuickLinks, { EMHome } from '../../components/QuickLinks/QuickLinks';
import WhyMyfembree, {
  whyMyfembreeEMProps
} from '../../components/WhyMyfembree/WhyMyfembree';

import EndoHero from '../../components/Hero/endo-hero';
import Cards from '../../components/EndoCards/cards';
import EndoRelief from '../../components/EndoRelief/endo-relief';
import Offer from '../../components/Offer/offer';
// import March from '../../components/March/march';

const Home = () => {
  return (
    <Layout section="EM" page="/endometriosis/">
      <>
        <EndoHero />

        <Cards />

        {/* <March /> */}

        <EndoRelief />

        <Offer />

        {/* <Hero
          {...{
            ...HeroHomeEMContent,
            className: 'md:pb-36'
          }}
        >
          <QuickLinks
            {...{
              ...EMHome,
              className: 'md:hidden'
            }}
          />
        </Hero> */}

        {/* <QuickLinks
          {...{
            ...EMHome,
            className: 'hidden md:block'
          }}
        /> */}
        {/* <QuickOverview {...endometriosis} /> */}

        {/* <a id="why-myfembree" className="relative -top-24 md:-top-44" /> */}
        {/* <WhyMyfembree {...whyMyfembreeEMProps} /> */}
        {/* <InnerReadyToStart /> */}
        {/* <CostSupport {...treatmentCostEM} /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/endometriosis/';
  const title =
    'Endometriosis Pain | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 0.4mg, 1mg, 0.5mg Tablets';
  const pageTitle =
    'Endometriosis Pain | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 0.4mg, 1mg, 0.5mg Tablets';
  const description =
    'Learn about MYFEMBREE®, an FDA-approved once-daily pill proven to relieve moderate to severe endometriosis pain in premenopausal women. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
